<template>
  <div>
    <apexchart :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>

var ru = require("apexcharts/dist/locales/ru.json")

export default {
  name: "Chart",
  props: {
    series: {default: []}
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 200,
          type: 'line',
          locales: [ru],
          defaultLocale: 'ru',
        },
        // stroke: {
          // curve: 'smooth',
        // },
        yaxis: {
          labels: {
            formatter: (value) => {
              return value + "к";
            }
          },
        },
        xaxis: {
          type: 'datetime',
          // categories: this.series.length ? Object.keys(this.series[0].data) : []
        }
      },
    }
  },
  methods: {},
  mounted() {
  },
}
</script>
