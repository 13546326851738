<template>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title class="pb-0" style="justify-content: space-between">
          Регионы
        </v-card-title>
        <v-card-text class="py-0">
          <v-row v-if="$store.state.user.rights.includes('smm')" style="align-items: center;">
            <span>Выгрузка для SMM:</span>
            <DatePeriod v-model="periodSMM"/>
            <v-btn class="ml-2 mt-2 v-btn-transp" style="display: inline-flex" :href="smmURL">
              Выгрузить
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-row>
          <v-row>
            <v-col cols="2">
              <v-menu
                  ref="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  bottom
                  nudge-bottom="35"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      outlined
                      :value="$root.dateToRus(date)"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  />
                </template>
                <v-date-picker
                    v-model="date"
                    no-title
                    scrollable
                    first-day-of-week="1"
                    locale="ru"
                    @change="reloadTable()"
                />
              </v-menu>
            </v-col>
            <v-col cols="3">
              <v-select
                  multiple
                  outlined
                  dense
                  :items="[{city: 'Выбрать все', id: 'all'}, ...$store.state.user.cities.filter(c=>c.id!=1)]"
                  item-text="city"
                  item-value="id"
                  v-model="selectedCities"
                  no-data-text="Данных нет"
                  @input="setCities"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="pt-0">
          <v-data-table
              hover
              :items="rows.filter((row)=>{return selectedCities.includes('all') || selectedCities.includes(row.city.id)})"
              :headers="getHeaders(date)"
              item-key="id"
              sort-by="payed_current.sum"
              sort-desc
              :loading="loadingTable"
              :footer-props="{'items-per-page-options':[-1]}"
              no-data-text="Данных нет"
              loading-text="Загрузка..."
              mobile-breakpoint="0"
          >
            <template v-if="$store.state.user.city.id==1" v-slot:body.prepend="{items}">
              <tr style="background: #aef2f6">
                <td></td>
                <td>Итого</td>
                <td style="white-space: nowrap">{{ total.oform_count }} шт ({{ $root.printCost(total.oform_sum) }})</td>
                <td style="white-space: nowrap">{{ total.isp_count }} шт ({{ $root.printCost(total.isp_sum) }})</td>
                <td>
                  <b>{{ $root.printCost(total.payed_prevprev_sum) }}</b>
                  <br>
                  <template v-if="$store.state.user.city.id==1">
                    <span style="color: #797979">{{ $root.printCost(total.finance_plan_prevprev) }} </span>
                    <span v-html="percent(total.payed_prevprev_sum, total.finance_plan_prevprev, true)"></span>
                    <br>
                  </template>
                  <span style="color: #3399ff">{{ $root.printCost(total.payed_prevprev_royalty) }}</span>
                  <br>
                  {{ total.cleaners_prevprev }} клин
                </td>
                <td>
                  <b>{{ $root.printCost(total.payed_prev_sum) }} </b>
                  <span v-html="percent(total.payed_prev_sum, total.payed_prevprev_sum, true)"></span>
                  <br>
                  <template v-if="$store.state.user.city.id==1">
                    <span style="color: #797979">{{ $root.printCost(total.finance_plan_prev) }} </span>
                    <span v-html="percent(total.payed_prev_sum, total.finance_plan_prev, true)"></span>
                    <br>
                  </template>
                  <span style="color: #3399ff">{{ $root.printCost(total.payed_prev_royalty) }}</span>
                  <br>
                  {{ total.cleaners_prev }} клин <span
                    v-html="percent(total.cleaners_prev, total.cleaners_prevprev, true)"></span>
                </td>
                <td>
                  <b>{{ $root.printCost(total.payed_current_sum) }} </b>
                  <span v-html="percent(total.payed_current_sum, total.payed_prev_sum, true)"></span>
                  <br>
                  <template v-if="$store.state.user.city.id==1">
                    <span style="color: #797979">{{ $root.printCost(total.finance_plan) }} </span>
                    <span v-html="percent(total.payed_current_sum, total.finance_plan, true)"></span>
                    <br>
                  </template>
                  <span style="color: #3399ff">{{ $root.printCost(total.payed_current_royalty) }}</span>
                  <br>
                  {{ total.cleaners_current }} клин
                </td>
                <td></td>
                <td v-if="$store.state.user.city.id==1 && total['week_'+(i-1)]" v-for="i in 5"
                    style="white-space: nowrap">
                  {{ $root.printCost(total['week_' + (i - 1)]) }}
                  <br>
                  <span> из {{ $root.printCost(total['week_plan_' + (i - 1)]) }}</span>
                  <br>
                  <span v-html="percent(total['week_'+(i-1)], total['week_plan_'+(i-1)], true)"></span>
                </td>
                <td style="white-space: nowrap">{{ $root.printCost(total.sms_sum) }}</td>
              </tr>
            </template>
            <template v-slot:item="{item, index}">
              <tr :style="$store.state.user.city.id==item.city.id ?'background: #aef2f6':''">
                <td>{{ index + 1 }}</td>
                <td>
                  <v-tooltip top v-if="crownColor(item).color">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-if="crownColor(item)"
                              style="position: relative;margin-top: -40px;margin-left: -8px;margin-right: -22px;"
                              :color="crownColor(item).color"
                              size="24"
                              v-bind="attrs"
                              v-on="on">mdi-crown-outline
                      </v-icon>
                    </template>
                    <span>Выручка более {{ $root.printCost(crownColor(item).level) }}</span>
                  </v-tooltip>
                  {{ item.city.name }}
                  <div style="color: #797979;font-size: 12px" v-if="+item.city.population">{{ item.city.population }}
                    тыс чел
                  </div>
                </td>
                <td style="white-space: nowrap">{{ item.oform.count }} шт. ({{ $root.printCost(item.oform.sum) }})</td>
                <td style="white-space: nowrap">{{ item.isp.count }} шт. ({{ $root.printCost(item.isp.sum) }})</td>
                <td style="white-space: nowrap">
                  <b>{{ $root.printCost(item.payed_prevprev.sum) }}</b>
                  <br>
                  <template v-if="$store.state.user.city.id==1">
                    <span style="color: #797979">{{ $root.printCost(item.finance_plan_prevprev) }} </span>
                    <span v-html="percent(item.payed_prevprev.sum, item.finance_plan_prevprev, true)"></span>
                    <br>
                  </template>
                  <span v-if="$store.state.user.city.id==1"
                        style="color: #3399ff">{{ $root.printCost(item.payed_prevprev.royalty) }}</span>
                  <br v-if="$store.state.user.city.id==1">
                  {{ item.cleaners_prevprev }} клин
                </td>
                <td style="white-space: nowrap">
                  <b>{{ $root.printCost(item.payed_prev.sum) }} </b>
                  <span v-html="percent(item.payed_prev.sum, item.payed_prevprev.sum, true)"></span>
                  <br>
                  <template v-if="$store.state.user.city.id==1">
                    <span style="color: #797979">{{ $root.printCost(item.finance_plan_prev) }} </span>
                    <span v-html="percent(item.payed_prev.sum, item.finance_plan_prev, true)"></span>
                    <br>
                  </template>
                  <span v-if="$store.state.user.city.id==1"
                        style="color: #3399ff">{{ $root.printCost(item.payed_prev.royalty) }}</span>
                  <br v-if="$store.state.user.city.id==1">
                  {{ item.cleaners_prev }} клин <span
                    v-html="percent(item.cleaners_prev, item.cleaners_prevprev, true)"></span>
                </td>
                <td style="white-space: nowrap">
                  <b>{{ $root.printCost(item.payed_current.sum) }} </b>
                  <span v-html="percent(item.payed_current.sum, item.payed_prev.sum, true)"></span>
                  <br>
                  <template v-if="$store.state.user.city.id==1">
                    <span style="color: #797979">{{ $root.printCost(item.finance_plan) }} </span>
                    <span v-html="percent(item.payed_current.sum, item.finance_plan, true)"></span>
                    <br>
                  </template>
                  <span v-if="$store.state.user.city.id==1"
                        style="color: #3399ff">{{ $root.printCost(item.payed_current.royalty) }}</span>
                  <br v-if="$store.state.user.city.id==1">
                  {{ item.cleaners_current }} клин
                </td>
                <td>
                  <template v-if="parseInt(item.work_start / 3600 / 24 / 31 / 12)">
                    {{ parseInt(item.work_start / 3600 / 24 / 31 / 12) }} г.
                  </template>
                  {{ parseInt(item.work_start / 3600 / 24 / 31 % 12) }} мес.
                </td>
                <td v-if="$store.state.user.city.id==1 && item.weeks[i-1]" v-for="i in 5" style="white-space: nowrap">
                  {{ $root.printCost(item.weeks[i - 1].sum) }}
                  <br>
                  <span> из {{ $root.printCost(item.weeks[i - 1].plan) }}</span>
                  <br>
                  <span v-html="percent(item.weeks[i-1].sum, item.weeks[i-1].plan, true)"></span>
                </td>
                <td v-if="$store.state.user.city.id==1" style="white-space: nowrap">{{
                    $root.printCost(item.sms_sum)
                  }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>

import YearMonth from "../../components/YearMonth";
import Chart from "../../components/Chart";
import DatePeriod from "../../components/DatePeriod";

export default {
  name: "Salary",
  components: {DatePeriod, Chart, YearMonth},
  data() {
    let today = this.$moment().format('YYYY-MM-DD');
    let years = [];
    for (let i = 0; i <= this.$moment().format('YYYY') - 2015; i++) {
      years.push((2015 + i).toString())
    }
    return {
      rows: [],
      date: today,
      selectedCities: this.$root.getData('regions', 'selectedCities') || ['all'],
      loadingTable: true,
      period: [this.$moment().startOf('month').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
      periodSMM: [this.$moment().startOf('month').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
      years,
    }
  },
  methods: {
    getHeaders(date) {
      // date = [date[0], this.$root.addZero(date[1]), '01'].join('-');
      let months = [
        this.$moment(date).add(-2, 'month').format('MMMM').toUpperCase(),
        this.$moment(date).add(-1, 'month').format('MMMM').toUpperCase(),
        this.$moment(date).format('MMMM').toUpperCase(),
      ];
      let title = this.$store.state.user.city.id == 1 ? ' Исп/7%/Клин' : ' Исп/Клин';
      let columns = [
        {text: '№', value: 'index'},
        {text: 'Регион', value: 'city.name'},
        {text: 'Офор сегодня', value: 'oform.sum'},
        {text: 'Исп сегодня', value: 'isp.sum'},
        {text: months[0] + title, value: 'payed_prevprev.sum'},
        {text: months[1] + title, value: 'payed_prev.sum'},
        {text: months[2] + title, value: 'payed_current.sum'},
        {text: 'Время работы', value: 'work_start'},
      ]
      if (this.$store.state.user.city.id == 1) {
        columns.push(...[
          {text: 'Неделя 1', value: 'weeks[0]'},
          {text: 'Неделя 2', value: 'weeks[1]'},
          {text: 'Неделя 3', value: 'weeks[3]'},
          {text: 'Неделя 4', value: 'weeks[4]'},
          (months[2].toLowerCase() === 'февраль' ? null : {text: 'Неделя 5', value: 'weeks[5]'}),
          {text: 'СМС', value: 'sms_sum'},
        ].filter(c => c));
      }
      return columns
    },
    percent(a, b, diff = false) {
      let res = parseInt(a / b * 100) || 0;
      if (res && diff) res = res - 100;
      let color = '';
      if (res < 0) {
        color = 'red';
      } else if (res > 0) {
        color = 'green';
        res = '+' + res;
      }
      return `<span style="color: ${color}">${res}%</span>`;
    },
    crownColor(row) {
      let max = Math.max(parseInt(row.payed_prevprev.sum), parseInt(row.payed_prev.sum), parseInt(row.payed_current.sum));
      if (max >= 1500000) return {color: '#ffd700', level: 1500000};
      if (max >= 1000000) return {color: '#b1b1b1', level: 1000000};
      if (max >= 500000) return {color: '#c96b1a', level: 500000};
      return {};
    },
    reloadTable() {
      this.rows = [];
      this.loadingTable = true;
      this.$store.state.server.request('regions/get', {date: this.date}, (data) => {
        this.loadingTable = false
        this.rows = data.response;
      }, () => {
        this.loadingTable = false
      })
    },
    setCities(a) {
      if (this.selectedCities[this.selectedCities.length - 1] === 'all') this.selectedCities = ['all'];
      else {
        var index = this.selectedCities.indexOf('all');
        if (index !== -1) {
          this.selectedCities.splice(index, 1);
        }
      }
      console.log({selectedCities: this.selectedCities});
      this.$root.saveData('regions', 'selectedCities', this.selectedCities);
    }
  },
  computed: {
    smmURL() {
      return this.$store.state.server.genUrl('order/downloadSMM', {period: this.periodSMM});
    },
    total() {
      let total = {
        sms_sum: 0,
        oform_count: 0,
        oform_sum: 0,
        isp_count: 0,
        isp_sum: 0,
        payed_prevprev_sum: 0,
        payed_prevprev_royalty: 0,
        payed_prev_sum: 0,
        payed_prev_royalty: 0,
        payed_current_sum: 0,
        payed_current_royalty: 0,
        cleaners_prevprev: 0,
        cleaners_prev: 0,
        cleaners_current: 0,
        finance_plan_prevprev: 0,
        finance_plan_prev: 0,
        finance_plan: 0,
        week_0: 0,
        week_1: 0,
        week_2: 0,
        week_3: 0,
        week_4: 0,
        week_plan_0: 0,
        week_plan_1: 0,
        week_plan_2: 0,
        week_plan_3: 0,
        week_plan_4: 0,
      };
      this.rows.forEach(row => {
        if (!this.selectedCities.includes('all') && !this.selectedCities.includes(row.city.id)) return true;
        total.sms_sum += parseInt(row.sms_sum) || 0;
        total.oform_count += parseInt(row.oform.count) || 0;
        total.oform_sum += parseInt(row.oform.sum) || 0;
        total.isp_count += parseInt(row.isp.count) || 0;
        total.isp_sum += parseInt(row.isp.sum) || 0;
        total.payed_prevprev_sum += parseInt(row.payed_prevprev.sum) || 0;
        total.payed_prevprev_royalty += parseInt(row.payed_prevprev.royalty) || 0;
        total.payed_prev_sum += parseInt(row.payed_prev.sum) || 0;
        total.payed_prev_royalty += parseInt(row.payed_prev.royalty) || 0;
        total.payed_current_sum += parseInt(row.payed_current.sum) || 0;
        total.payed_current_royalty += parseInt(row.payed_current.royalty) || 0;
        total.cleaners_prevprev += parseInt(row.cleaners_prevprev) || 0;
        total.cleaners_prev += parseInt(row.cleaners_prev) || 0;
        total.cleaners_current += parseInt(row.cleaners_current) || 0;
        total.finance_plan_prevprev += parseInt(row.finance_plan_prevprev) || 0;
        total.finance_plan_prev += parseInt(row.finance_plan_prev) || 0;
        total.finance_plan += parseInt(row.finance_plan) || 0;
        for (let i in row.weeks) {
          total[`week_${i}`] += parseInt(row.weeks[i].sum) || 0;
          total[`week_plan_${i}`] += parseInt(row.weeks[i].plan) || 0;
        }
      });
      return total;
    }
  },
  mounted() {
    this.reloadTable();
  },

}
</script>
